import SingleProthesmia from "./SingleProthesmia";

const ProthesmiaCard = ({ input, proth }) => {
  return (
    <section className="prothesmies_section">
      {" "}
      {input !== "" &&
        proth.map((r, i) => <SingleProthesmia key={r.eidos} proth={r} />)}
    </section>
  );
};

export default ProthesmiaCard;
