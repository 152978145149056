import { useState, useEffect } from "react";
import { prothesmiesApopoiisis } from "prothesmies";
import { dikastiria } from "../Util/dikastiria";
import ProthesmiaCard from "./ProthesmiaCard";
import ProthesmiesHeader from "../Prothesmies/ProthesmiesHeader";

// import prothDep from '../../package.alias.json';

const transformProthToObjects = (proth) => {
  const arr = [];
  Object.keys(proth).forEach((r) => {
    const obj = {};
    if (r !== "dikasimos" && r !== "katathesi" && !r.includes("Details")) {
      obj.prothesmia = proth[r];
      Object.keys(proth).forEach((k) => {
        if (k === `${r}Details`) {
          obj.details = proth[k];
          obj.eidos = r;
          arr.push(obj);
        }
      });
    }
  });
  return arr;
};

const Apopoiisi = () => {
  const [input, setInput] = useState("");
  const [edra, setEdra] = useState("Αθηνών");
  const [proth, setProth] = useState("");
  const [epidosiDone, setEpidosiDone] = useState(undefined);

  const [perifereia, setPerifereia] = useState("Αθηνών");

  const [dimosio, setDimosio] = useState(false);
  const [exoterikou, setExoterikou] = useState(false);

  const handleEdraChange = (event) => {
    setEdra(event.target.value);
  };
  const handlePerifereiaChange = (event) => {
    setPerifereia(event.target.value);
  };
  const handleEpidosiDoneChange = (event) => {
    setEpidosiDone(event.target.value);
  };

  useEffect(() => {
    if (input?.slice(0, 2) === "20" && input.split("-")[0].length === 4) {
      const timeOutId = setTimeout(
        () => setProth(prothesmiesApopoiisis(input, { exoterikou })),
        100
      );
      return () => clearTimeout(timeOutId);
    }
  }, [input, exoterikou]);

  return (
    <section>
      <ProthesmiesHeader />

      <section className="container_search">
        <h3 className="h3_prothesmia">
          Υπολογισμός Προθεσμίας Αποδοχής Κληρονομίας
        </h3>
        <h4 className="h3_prothesmia">
          Χρησιμοποιεί το{" "}
          <a
            href="https://github.com/imertz/prothesmies"
            target="_blank"
            rel="noreferrer"
          >
            prothesmies
          </a>{" "}
          έκδοση 0.8.5
        </h4>
        <form className="form_search">
          {/* <label htmlFor="username">Email:</label> */}
          <div className="input-container ic1">
            <input
              className="input"
              placeholder=" "
              type="date"
              id="Αναζήτηση"
              autoComplete="on"
              min="2022-02-01"
              onChange={(e) => {
                parseInt(input.split("-")[0]) < 2022
                  ? setInput("2022-01-01")
                  : setInput(e.target.value);
              }}
              value={input || ""}
              required
            />
            <div className="cut"></div>
            <label htmlFor="Αναζήτηση" className="placeholder">
              Ημερομηνία Θανάτου
            </label>
          </div>

          <div className="input-container ic2">
            <select
              value={exoterikou ? "ναι" : "όχι"}
              onChange={(e) =>
                e.target.value === "ναι"
                  ? setExoterikou(true)
                  : setExoterikou(false)
              }
              className="input"
            >
              <option key="Όχι" value="όχι">
                Όχι
              </option>
              <option key="Ναι" value="ναι">
                Ναι
              </option>
            </select>

            <div className="cut"></div>
            <label htmlFor="exoterikou" className="placeholder">
              Κληρονόμος κάτοικος εξωτερικού;
            </label>
          </div>
        </form>
      </section>

      <ProthesmiaCard proth={transformProthToObjects(proth)} input={input} />
    </section>
  );
};

export default Apopoiisi;
