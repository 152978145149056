import { useState } from "react";
import Nomothesia from "./Nomothesia";
import Ypologismos from "./Ypologismos";

const details = [
  { text: "epidosi", greek: "Επίδοση" },
  { text: "apopseis", greek: "Απόψεις Διοίκησης" },
  { text: "katathesi", greek: "Κατάθεση ένδικου βοηθήματος" },
  {
    text: "paremvasi",
    greek: "Κατάθεση παρέμβασης και ανταγωγής και η επίδοση",
  },
  { text: "prothesmiaEpidosis", greek: "Προθεσμία Επίδοσης" },
  { text: "prosthetoiLogoi", greek: "Πρόσθετοι Λόγοι" },
];
const getGreekText = (text) => {
  return details.find((r) => r.text === text);
};
const SingleProthesmia = ({ proth }) => {
  const [activeTab, setActiveTab] = useState("none");

  const handleTab1 = () => {
    if (activeTab !== "Ypogismos") {
      setActiveTab("Ypologismos");
    }
    if (activeTab === "Ypologismos") {
      setActiveTab("none");
    }
  };
  const handleTab2 = () => {
    if (activeTab !== "Nomothesia") {
      setActiveTab("Nomothesia");
    }
    if (activeTab === "Nomothesia") {
      setActiveTab("none");
    }
  };

  return (
    <article className="case_card">
      <p className="prothesmia_text">
        {getGreekText(proth.eidos).greek} μέχρι{" "}
        <span className="prothesmia_date">
          {new Date(proth.prothesmia).toLocaleDateString()}
        </span>
      </p>
      {/* <p>{proth.details}</p> */}
      <ul className="nav">
        <button className="piso_button" onClick={handleTab1}>
          Υπολογισμός
        </button>
        <button className="piso_button" onClick={handleTab2}>
          Νομοθεσία
        </button>
      </ul>
      {activeTab === "Ypologismos" && <Ypologismos details={proth.details} />}
      {activeTab === "Nomothesia" && <Nomothesia details={proth.details} />}
      {activeTab === "none" && <p></p>}
    </article>
  );
};
// } else {
//   return (<>
//     <p key={proth}>{proth[r]}</p>
//   </>
//   )
// }

export default SingleProthesmia;
