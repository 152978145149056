import { useEffect, useState, useRef } from "react";

import Register from "./components/Register";
import Login from "./components/Login";
import Home from "./components/Home";
import Layout from "./components/Layout";
import Rotating from "./components/rotating";

import Editor from "./components/Editor";
import Admin from "./components/Admin";
import Missing from "./components/Missing";
import Unauthorized from "./components/Unauthorized";
import Unconfirmed from "./components/Unconfirmed";
import Lounge from "./components/Lounge";
import LinkPage from "./components/LinkPage";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import ViewCase from "./components/SingleCase/ViewCase";
import OutViewCase from "./components/OutSolon/ViewCaseOutSolon";
import ResetPassword from "./components/ResetPassword";
import Forgot from "./components/Forgot";
import Imerologio from "./components/Imerologio";
import Etairies from "./components/Etairies/Etairies";
import EauctionAfm from "./components/EauctionAfm/EauctionAfm";
import Poinika from "./components/Poinika/Poiniko";
import Adjustice from "./components/Adjustice/Adjustice";
import Updates from "./components/Updates/Updates";
import Prothesmies from "./components/Prothesmies/Prothesmies";
import Exsolon from "./components/Exsolon/Exsolon";
import Subscribe from "./components/Subscribe";
import AiMisthosi from "./components/AI/AiMisthosi";
import Calendar from "./components/Calendar/Calendar";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Mikrodiafores from "./components/Mikrodiafores/Mikrodiafores";
import NeaDikonomiaSte from "./components/NeaDikonomiaSte/NeaDikonomiaSte";
import ViewAdjCase from "./components/SingleCase/ViewAdjCase";
import DiatPliromis from "./components/DiatPliromis/DiatPliromis";
import ViewEtairia from "./components/SingleCase/ViewEtairia";
import Apopoiisi from "./components/Apodochi/Apodochi";
import Confirmation from "./components/Confirmation";
import { Search } from "./components/Search/Search";
import { Search2 } from "./components/Search/Search2";
import MyPortal from "./MyPortal";
import ProfilePhoto from "./components/UploadPhoto/Profile";
import Profile from "./components/Profile/Profile";
import Post from "./components/Social/Post";
import CreatePost from "./components/Social/CreatePost";

import UploadPhoto from "./components/UploadPhoto/UploadPhoto";
import Administrator from "./components/Admin/Administrator";
import NewPost from "./components/Social/NewPost";
import Ekloges from "./components/Social/Ekloges";
import EklogesRe from "./components/Social/EklogesRe";
import SinglePost from "./components/Social/SinglePost";
import LeaseForm from "./components/Templates/MisthosiTemplate";
import LeaseFormAi from "./components/Templates/MisthosiAi";
import MyKtima from "./MyKtima";
import OutSolon from "./components/OutSolon/Admin";
import Diamesolavisi from "./components/Diamesolavisi/Diamesolavisi";

function App() {
  const location = useLocation();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<Login />} />
        <Route path="rotating" element={<Rotating />} />

        <Route path="register" element={<Register />} />
        <Route path="forgot" element={<Forgot />} />
        <Route path="linkpage" element={<LinkPage />} />
        <Route path="prothesmies" element={<Prothesmies />} />
        <Route path="prothesmies/neataktiki" element={<Prothesmies />} />
        <Route path="prothesmies/mikrodiafores" element={<Mikrodiafores />} />
        <Route path="prothesmies/ste" element={<NeaDikonomiaSte />} />
        <Route path="prothesmies/diamesolavisi" element={<Diamesolavisi />} />

        <Route path="prothesmies/diatpliromis" element={<DiatPliromis />} />
        <Route path="prothesmies/apopoiisis" element={<Apopoiisi />} />
        <Route path="myportal" element={<MyPortal />} />
        <Route path="myktima" element={<MyKtima />} />

        <Route path="search" element={<Search />} />
        <Route path="search2" element={<Search2 />} />
        <Route
          path="qr/:path/:id"
          element={<Navigate to={location.pathname.replace("/qr", "")} />}
        />
        <Route path="confirmation" element={<Confirmation />} />

        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="unconfirmed" element={<Unconfirmed />} />
        <Route path="/reset/:uid" element={<ResetPassword />} />

        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Ekloges />} />
            <Route path="/ekloges" element={<EklogesRe />} />

            <Route path="/astika" element={<Admin />} />
            <Route path="/imerologio" element={<Admin />} />
            <Route path="/exsolon" element={<Exsolon />} />

            <Route path="/etairies" element={<Etairies />} />
            <Route path="/eauction" element={<EauctionAfm />} />
            <Route path="/poinika" element={<Poinika />} />

            {/* <Route path="/eauction/:afm" element={<Eauction />} /> */}
            <Route path="/adjustice" element={<Adjustice />} />
            <Route path="/updates" element={<Updates />} />

            <Route path="/cases/:caseId/:qr" element={<ViewCase />} />
            <Route path="/cases/:caseId" element={<ViewCase />} />
            <Route path="/outsolon/:caseId" element={<OutViewCase />} />

            <Route path="/adjustice/:caseId" element={<ViewAdjCase />} />
            <Route path="/etairies/:caseId/:page" element={<ViewEtairia />} />
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/upload" element={<ProfilePhoto />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/:profileId" element={<Profile />} />
            <Route path="/post" element={<Post />} />
            <Route path="/createpost" element={<CreatePost />} />
            <Route path="/newpost" element={<NewPost />} />
            <Route path="/admin" element={<Administrator />} />
            <Route path="/labs" element={<LeaseForm />} />
            <Route path="/ai" element={<AiMisthosi />} />
            <Route path="/labsai" element={<LeaseFormAi />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/out" element={<OutSolon />} />

            <Route path="/post/:postUuid" element={<SinglePost />} />
          </Route>

          <Route element={<RequireAuth />}>
            <Route path="editor" element={<Editor />} />
          </Route>

          <Route element={<RequireAuth />}>
            <Route path="dashboard" element={<Admin />} />
          </Route>

          <Route element={<RequireAuth />}>
            <Route path="lounge" element={<Lounge />} />
          </Route>

          {/* catch all */}
          <Route path="*" element={<Missing />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
