import { axiosPrivate } from "../api/axios";
import { useState, useCallback, useEffect, useContext } from "react";
import ProthCard from "./ProthCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrashCan,
  faCircleCheck,
} from "@fortawesome/free-regular-svg-icons";
import { StarIcon } from "@heroicons/react/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/solid";

import { useNavigate, Link } from "react-router-dom";
import { DateTime, Settings } from "luxon";
import AuthContext from "../context/AuthProvider";
import { Select, SelectItem, Icon } from "@tremor/react";
import { set } from "date-fns";
Settings.defaultLocale = "el";
const industries = [
  { key: "taktiki", name: "Νέα Τακτική" },
  { key: "mikro", name: "Νέες Μικροδιαφορές" },
  { key: "diatagi", name: "Διαταγή Πληρωμής/Απόδοσης" },

  { key: "loipa", name: "Άλλο" },
];

Date.createFromMysql = function (mysql_string) {
  var t,
    result = null;

  if (typeof mysql_string === "string") {
    t = mysql_string.split(/[- :]/);

    //when t[3], t[4] and t[5] are missing they defaults to zero
    result = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);
  }

  return result;
};
export function formatDate(date) {
  const newDate = new Date(Date.createFromMysql(date));

  const formatedDate = DateTime.fromISO(newDate.toISOString())
    .plus({ minutes: Math.abs(new Date().getTimezoneOffset()) })
    .toFormat("ff");
  return formatedDate;
}

export function formatDateMinus(date) {
  const newDate = new Date(Date.createFromMysql(date));
  const formatedDate = DateTime.fromISO(newDate.toISOString()).minus({
    minutes: Math.abs(new Date().getTimezoneOffset()),
  });
  // return datetime in the format of 'YYYY-MM-DD HH:MM:SS'
  return formatedDate.toFormat("yyyy-MM-dd HH:mm:ss");
}

export function formatNowDate() {
  const newDate = new Date();

  const formatedDate = DateTime.fromISO(newDate.toISOString()).toFormat("ff");
  return formatedDate;
}

const CaseCard = ({
  singleCase,
  prothesmiesSingle,
  handleDeleteCase,
  handleAddSelectedDiadikasia,
  credits,
  setCredits,
}) => {
  const [note, setNote] = useState(singleCase?.note);
  const [showProth, setShowProth] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const { checking, setChecking } = useContext(AuthContext);
  const [checkingStatus, setCheckingStatus] = useState("notstarted");
  const [faved, setFaved] = useState(singleCase.fav === 1 ? true : false);
  const [last_checked, setLast_checked] = useState(
    formatDate(singleCase.last_checked)
  );
  const [selectedDiadikasia, setSelectedDiadikasia] = useState(
    singleCase.selectedDiadikasia
  );

  const [checkAppeal, setCheckAppeal] = useState(
    singleCase.check_appeal === 1 ? true : false
  );

  const sendSelectedDiadikasia = async (e) => {
    handleAddSelectedDiadikasia(singleCase.id, e);
    setSelectedDiadikasia(e);
    try {
      await axiosPrivate.post(
        `/userinput/select_diadikasia/${singleCase.id}`,
        JSON.stringify({ data: e }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (checkingStatus === "started" && checking.length === 0) {
      setCheckingStatus("finished");
      setLast_checked(formatNowDate());
    }
  }, [checking, checkingStatus]);

  const handleClicks = async (e) => {
    if (e.target.parentNode.getAttribute("data-icon") === "pen-to-square") {
      setShowEdit(!showEdit);
    }
    if (e.target.parentNode.getAttribute("data-icon") === "circle-check") {
      setShowEdit(!showEdit);
      try {
        const id = e.target.parentNode.getAttribute("data-key");
        await axiosPrivate.post("/cases/note", JSON.stringify({ id, note }), {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });
      } catch (err) {
        console.error(err);
      }
    }
    if (
      e.currentTarget?.firstChild?.getAttribute("data-icon") === "trash-can"
    ) {
      try {
        const id = e.currentTarget.getAttribute("data-key");
        await axiosPrivate.patch("/cases", JSON.stringify({ key: id }), {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });
        handleDeleteCase(id);
      } catch (err) {
        console.error(err);
      }
    }
    // if (e.target?.parentNode?.getAttribute('data-icon') === null) {
    //   handleOnClick()
    // }
  };

  const handleAppeal = async (e) => {
    setCheckAppeal(!checkAppeal);

    try {
      const id = e.target.getAttribute("data-key");
      await axiosPrivate.post(
        "/appeals",
        JSON.stringify({ id, check: !checkAppeal }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
    } catch (err) {
      console.error(err);
    }
  };
  const handleProth = async (e) => {
    setShowProth(!showProth);
  };

  const handleCheck = async (e) => {
    setChecking([
      ...checking,
      { case_id: singleCase.id, category: "solon_check" },
    ]);
    setCredits(credits - 5);
    setCheckingStatus("started");
    try {
      const id = e.target.getAttribute("data-key");
      await axiosPrivate.post("/cases/check", JSON.stringify({ id }), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleFav = async () => {
    setFaved(!faved);
    try {
      const id = singleCase.id;
      await axiosPrivate.post(
        "/cases/fav",
        JSON.stringify({ id, fav: !faved }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
    } catch (err) {
      console.error(err);
    }
  };

  const navigate = useNavigate();
  const handleOnClick = useCallback(() => {
    navigate(`/cases/${singleCase.id}`, { replace: false });
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, [navigate, singleCase]);
  return (
    <article className="case_card" onClick={handleClicks}>
      {/* <div key={`ed${singleCase.id}`} className="edit_case"><p ><span onClick={handleDelete} data-key={singleCase.id}>✏️</span></p></div> */}
      <span title="Αύξων Αριθμός" className="number">
        {singleCase?.no ? singleCase?.no + 1 : "1"}
      </span>

      <div key={singleCase.id} className="close_case">
        <p>
          <div
            className="fav-icon"
            onClick={() => {
              handleFav();
            }}
          >
            {faved && (
              <Icon
                icon={StarIconSolid}
                color="yellow"
                className={`cursor-pointer fav-star ml-[-50px]`}
                size="md"
              />
            )}

            <Icon
              icon={StarIcon}
              color="yellow"
              tooltip="Προσθήκη στα Σημαντικά"
              className="cursor-pointer ml-[-36px]"
              size="md"
            />
          </div>
          <span
            style={{
              border: "none",
              cursor: "pointer",
              color: "red",
              backgroundColor: "white",
              paddingLeft: "5px",
              fontSize: "1.3em",
            }}
            title="Διαγράψτε την υπόθεση"
            onClick={handleClicks}
            data-key={singleCase.id}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </span>
        </p>
      </div>

      <h4>
        <em>Σημείωση:</em>{" "}
        {showEdit ? (
          <>
            <input
              className="edit-note"
              placeholder={note}
              value={note}
              size={note.length}
              onChange={(e) => setNote(e.target.value)}
            />
            <button
              style={{
                border: "none",
                cursor: "pointer",
                color: "green",
                backgroundColor: "white",
                paddingLeft: "5px",
                fontSize: "1.3em",
              }}
              onClick={handleClicks}
              data-key={singleCase.id}
              data-icon="circle-check"
            >
              <FontAwesomeIcon data-key={singleCase.id} icon={faCircleCheck} />
            </button>
          </>
        ) : (
          <span>
            {note}
            <button
              data-icon="pen-to-square"
              title="Επεξεργαστείτε την σημείωση"
              style={{
                border: "none",
                cursor: "pointer",
                color: "green",
                backgroundColor: "white",
                paddingLeft: "5px",
                fontSize: "1.3em",
              }}
              onClick={handleClicks}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
          </span>
        )}
      </h4>
      <h4>
        <em>ΓΑΚ:</em> {singleCase?.gak}/{singleCase?.year}
      </h4>
      <h4>
        <em>Δικαστήριο:</em> {singleCase?.court}
      </h4>
      {singleCase?.eak?.length > 2 && (
        <h4>
          <em>Ημ/νία κατάθεσης: </em>
          {singleCase?.eak?.split("-")[1]}
        </h4>
      )}
      {singleCase?.arithmos_pinakiou?.length > 2 && (
        <h4>
          <em>Αριθμός Πινακίου:</em> {singleCase?.arithmos_pinakiou}
        </h4>
      )}
      {singleCase?.diadikasia?.length > 2 && (
        <h4>
          <em>Διαδικασία:</em> {singleCase?.diadikasia}
        </h4>
      )}
      {singleCase?.antikeimeno?.length > 2 && (
        <h4>
          <em>Αντικείμενο:</em> {singleCase?.antikeimeno}
        </h4>
      )}
      {singleCase?.apotelesma?.length > 2 && (
        <h4>
          <em>Αποτέλεσμα:</em> {singleCase?.apotelesma}
        </h4>
      )}
      {singleCase?.arithmos_apofasis?.length > 2 && (
        <h4>
          <em>Αρ.Απόφασης:</em> {singleCase?.arithmos_apofasis}
        </h4>
      )}
      {singleCase?.created_at && (
        <h4>
          <em>Δημιουργία: </em> {formatDate(singleCase?.created_at)}
        </h4>
      )}

      {singleCase?.appeal && (
        <h4>
          <em>Ένδικο μέσο: </em> {singleCase?.appeal}
        </h4>
      )}
      {!singleCase?.last_checked && (
        <h4 className="loading">
          Γίνεται αναζήτηση στο solon.gov.gr. Μόλις ολοκληρωθεί θα σας έρθει
          ενημέρωση.<div className="lds-hourglass"></div>
        </h4>
      )}
      {/* {prothesmiesSingle.filter(r => r.eidos !== 'dikasimos').length > 0 && <h4 className='prothesmies' onClick={handleProth}>ΠΡΟΘΕΣΜΙΕΣ ⬇️</h4>} */}
      {/* <div className={showProth ? '' : 'offscreen'}><ProthCard prothesmiesSingle={prothesmiesSingle} /></div>*/}

      {/* {(singleCase.arithmos_apofasis?.includes('/') && !singleCase.appeal && !singleCase.antikeimeno?.includes('ΔΙΑΤΑΓ') && !singleCase.diadikasia?.includes('ΔΙΑΤΑΓ')) && <div>
        <h4 >Έλεγχος για άσκηση ενδίκου μέσου <input
          data-key={singleCase.id}
          type="checkbox"
          id="checkappeal"
          onChange={handleAppeal}
          checked={checkAppeal}
        /></h4>


      </div>} */}
      {singleCase?.last_checked && (
        <h4 data-case="last_checked">
          <em>Τελευταία ενημέρωση: </em> {last_checked}
        </h4>
      )}
      {singleCase?.last_checked && (
        <h4 data-case="last_checked">
          <Select
            placeholder="Επιλέξτε Διαδικασία"
            className="max-w-xs z-[1000000]"
            onValueChange={sendSelectedDiadikasia}
            value={selectedDiadikasia}
          >
            {industries.map((industry) => (
              <SelectItem key={industry.key} value={industry.key}>
                {industry.name}
              </SelectItem>
            ))}
          </Select>
        </h4>
      )}
      {singleCase?.last_checked && (
        <div className="add_case_buttons">
          <Link
            to={`/cases/${singleCase?.id}`}
            className="addcase_modal analytika"
          >
            Αναλυτικά
          </Link>
          <button
            className={`addcase_modal check_now button--loading`}
            data-key={singleCase.id}
            onClick={handleCheck}
            disabled={
              Array.isArray(checking) &&
              checking.some(
                (e) =>
                  e.case_id === singleCase.id && e.category === "solon_check"
              )
            }
          >
            Ελέγξτε ξανά
          </button>
        </div>
      )}
    </article>
  );
};

export default CaseCard;
