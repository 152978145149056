import { useState, useEffect } from "react";
import { prothesmiesNDSte } from "prothesmies";
import { dikastiria } from "../Util/dikastiria";
// import prothDep from '../../package.alias.json';
import ProthesmiaCard from "./ProthesmiaCard";
import ProthesmiesHeader from "../Prothesmies/ProthesmiesHeader";

const transformProthToObjects = (proth) => {
  const arr = [];
  Object.keys(proth).forEach((r) => {
    const obj = {};
    if (r !== "katathesi" && !r.includes("Details")) {
      obj.prothesmia = proth[r];
      Object.keys(proth).forEach((k) => {
        if (k === `${r}Details`) {
          obj.details = proth[k];
          obj.eidos = r;
          arr.push(obj);
        }
      });
    }
  });
  return arr;
};

const NeaDikonomiaSte = () => {
  const [input, setInput] = useState("");
  const [epidosi, setEpidosi] = useState(undefined);
  const [proth, setProth] = useState("");

  useEffect(() => {
    if (input?.slice(0, 2) === "20" && input.split("-")[0].length === 4) {
      const timeOutId = setTimeout(
        () => setProth(prothesmiesNDSte(input, epidosi)),
        100
      );
      return () => clearTimeout(timeOutId);
    }
  }, [input, epidosi]);

  console.log(proth);

  return (
    <section>
      <ProthesmiesHeader />

      <section className="container_search">
        <h3 className="h3_prothesmia">
          Υπολογισμός Προθεσμιών Νέας Δικονομίας ΣτΕ
        </h3>
        <h4 className="h3_prothesmia">
          Χρησιμοποιεί το{" "}
          <a
            href="https://github.com/imertz/prothesmies"
            target="_blank"
            rel="noreferrer"
          >
            prothesmies
          </a>{" "}
          έκδοση 0.8.5
        </h4>
        <form className="form_search">
          {/* <label htmlFor="username">Email:</label> */}
          <div className="input-container ic1">
            <input
              className="input"
              placeholder=" "
              type="date"
              id="Αναζήτηση"
              autoComplete="on"
              onChange={(e) => setInput(e.target.value)}
              value={input || ""}
              required
              min={new Date("2024-09-16").toISOString().split("T")[0]}
            />
            <div className="cut"></div>
            <label htmlFor="Αναζήτηση" className="placeholder">
              Ημερομηνία Κατάθεσης
            </label>
          </div>

          <div className="input-container ic1">
            <input
              className="input"
              placeholder=" "
              type="date"
              id="Επίδοση"
              autoComplete="on"
              onChange={(e) => setEpidosi(e.target.value)}
              value={epidosi || ""}
              required
              min={new Date("2024-09-16").toISOString().split("T")[0]}
            />
            <div className="cut"></div>
            <label htmlFor="Επίδοση" className="placeholder">
              Ημερομηνία Επίδοσης
            </label>
          </div>
        </form>
      </section>

      <ProthesmiaCard proth={transformProthToObjects(proth)} input={input} />
    </section>
  );
};

export default NeaDikonomiaSte;
