import React from "react";
import { NavLink, useLocation } from "react-router-dom";

function ProthesmiesHeader() {
  const location = useLocation();
  return (
    <div>
      <div className="prothesmies-buttons">
        {/* <Link className={location.pathname.includes('gemi') ? 'etairia-button active' : 'etairia-button'} to={`/etairies/${singleEtairia?.afm}/gemi`}>ΓΕΜΗ</Link> */}
        <NavLink className="prothesmies-button" to={"/prothesmies/neataktiki"}>
          Νέα Τακτική
        </NavLink>
        <NavLink
          className="prothesmies-button"
          to={"/prothesmies/mikrodiafores"}
        >
          Νέα Διαδικασία Μικροδιαφορών
        </NavLink>
        <NavLink
          className="prothesmies-button"
          to={"/prothesmies/diatpliromis"}
        >
          Διαταγές Πληρωμής
        </NavLink>
        <NavLink className="prothesmies-button" to={"/prothesmies/apopoiisis"}>
          Αποποίηση
        </NavLink>
        <NavLink
          className="prothesmies-button"
          to={"/prothesmies/diamesolavisi"}
        >
          Διαμεσολάβηση
        </NavLink>
        <NavLink className="prothesmies-button" to={"/prothesmies/ste"}>
          Νέα Δικον. ΣτΕ
        </NavLink>
      </div>
    </div>
  );
}

export default ProthesmiesHeader;
